<template>
  <v-container fluid>
    <component :modelsurat="modeltype" :is="currentRole" :dSurat="dataSurat" :letterguid="lGUID" />

    <v-dialog v-model="isLoading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
          <v-layout justify-center align-center>
            <v-card
              width="70vW"
              class="pa-md-2 mx-lg-auto"
              outlined
            >
              <v-list-item three-line centered>
                <v-list-item-content>
                  <div class="overline mb-4">Processing</div>
                  <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                    ></v-progress-linear>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>

          </v-layout>
      </v-container>
    </v-dialog>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
  
</template>

<script>
import { mapGetters } from 'vuex'

import viewSurata from './viewTemplates/viewSuratMasuk'
import viewSuratb from './viewTemplates/viewSuratTTD'

import { getDataSuratByLetterGUID } from '@/api/datasurat'
import { updateLetterInOut, getSMByID, getSMByUposandLetterIDwithoutOpenSts } from '@/api/letterinout'
import moment from 'moment'
import {forEach} from 'p-iteration'
import secureStorage from '@/utils/secureStorage'

export default {
  name: 'ViewSurat',
  components: { viewSurata, viewSuratb},
  data() {
    return {
      dataSurat: null,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      currentRole: '',
      lGUID: '',
      idSM: '',
      isLoading: false,
      modeltype: 0
    }
  },
  created() {
    this.lGUID = this.$route.params && this.$route.params.lguid  
    //let idSMa = this.$route.params && this.$route.params.idSM  
    // this.idSM = parseInt(idSMa)
  },
  mounted() {
    if(this.lGUID){
      this.fetchData(this.lGUID)
    }
  },
  methods: {
    async fetchData (lguid) {
      const vm = this
      vm.isLoading = true

      // console.log('fetchData',lguid)
      getDataSuratByLetterGUID(lguid).then(response => {
        // console.log(response)
        var data = response.data
        if(data.length > 0){
          vm.dataSurat = data[0]
          
          vm.fetchSuratMasuk(vm.dataSurat.id)

          if(data[0].eolettertype){
            vm.dataSurat.eolettertype = data[0].eolettertype
            vm.modeltype = vm.dataSurat.eolettertype.modelsurat
            
            if (vm.dataSurat.eolettertype.modelsurat == 1) {
              vm.currentRole = 'viewSurata'
            }else if(vm.dataSurat.eolettertype.modelsurat >= 2 && vm.dataSurat.eolettertype.modelsurat <= 5){
              vm.currentRole = 'viewSuratb'
            }else {
              vm.currentRole = 'viewSurata'
            }
          }
        }else{
          vm.toast = {
            show: true, color:'red', text: 'Data surat not found!', timeout: 2000
          }
        }
        
        vm.isLoading = false
      }).catch(err => {
        console.log('error', err)
        // vm.$router.push('/401')
        vm.isLoading = false
      })
    },
    fetchSuratMasuk(idLetter){
      const vm = this
      vm.isLoading = true
      var crole = secureStorage.getItem('currRole')
      // console.log('crole id', crole.id)
      // console.log('fetchSuratMasuk', idLetter)
      getSMByUposandLetterIDwithoutOpenSts(crole.id, idLetter).then(response => {
        var data = response.data
        // console.log('getSMByUposandLetterID tot', data.length)
        if(data.length > 0){
          forEach(data, (element) => {
            //Update log
            //console.log(element)
            var logto = element.log_to
            var m = moment()
            var str = ''
            if (logto == null) {
              str = m.format('DD/MM/YYYY HH:mm:ss') + ' - Read'
            } else {
              str = logto + '<br>' + m.format('DD/MM/YYYY HH:mm:ss') + ' - Read'
            }

            var updData = { log_to: str, to_isOpen: 1 }
            vm.isLoading = true
            // console.log('updateLetterInOut data', updData)
            updateLetterInOut(updData, element.id).then(response => {
              // console.log('updateLetterInOut resp', response.data)
              vm.isLoading = false
            }).catch(err => {
              // console.log(err)
              vm.isLoading = false
            })
          })   
               
        }
        vm.isLoading = false
      }).catch(err => {
        console.log('error', err)
        // vm.$router.push('/401')
        vm.isLoading = false
      })
    },
  }
}
</script>
